import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Route,
  UrlSegment,
} from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private userService: UserService) {}

  private isAuthenticated(): Observable<boolean> {
    return this.userService.user$.pipe(
      first(),
      map((user) => {
        if (!user || !/(@buk\.io)|(@sijun\.kr)$/.test(user?.email)) {
          const redirect_to = encodeURIComponent(`${environment.bukOrigin}/login?redirect_to=${window.location.href}`);
          location.href = `${environment.bukOrigin}/logout?redirect_to=${redirect_to}`;
        }

        return !!user;
      })
    );
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.isAuthenticated();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.isAuthenticated();
  }
}
