import { Component, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { sha256 } from 'js-sha256';

import { environment } from '../environments/environment';

import { SidenavService } from './shared/services/sidenav.service';
import { UserService } from './user.service';
import { AppTranslateService } from './shared/services/app-translate.service';
import { ChannelTalkService } from './channel-talk.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(
    element: ElementRef,
    router: Router,
    renderer: Renderer2,
    translateService: TranslateService,
    appTranslateService: AppTranslateService,
    sidenavService: SidenavService,
    userService: UserService,
    angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag,
  ) {
    if (location.host === 'dashboard.buk.io') {
      angulartics2GoogleGlobalSiteTag.startTracking();
    }
    // trigger load material icon font
    const icon = document.createElement('div');
    icon.style.visibility = 'hidden';
    icon.style.pointerEvents = 'none';
    icon.style.position = 'fixed';
    icon.classList.add('material-icons');
    icon.innerHTML = 'check_circle';

    renderer.appendChild(element.nativeElement, icon);
    setTimeout(() => {
      renderer.removeChild(element.nativeElement, icon);
    }, 1000);

    document.addEventListener('DOMNodeInserted', function () {
      const elements = document.querySelectorAll('[aria-owns]');

      elements.forEach((element) => {
        element.removeAttribute('aria-owns');
      });
    });
  }

  logout(): void {
    location.href = `${
      environment.bukOrigin
    }/logout?redirect_to=${encodeURIComponent(location.href)}`;
  }
}
