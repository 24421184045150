import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialCommonModule } from '../material-common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { ChartsModule } from 'ng2-charts';
import { Angulartics2Module } from 'angulartics2';

import { UserDebugInfoComponent } from './components/user-debug-info/user-debug-info.component';
import { ImageInputComponent } from './components/image-input/image-input.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { BukOembedCardComponent } from './components/buk-oembed-card/buk-oembed-card.component';
import { CommonHeaderComponent } from './components/common-header/common-header.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MomentPeriodDatepickerComponent } from './components/moment-period-datepicker/moment-period-datepicker.component';
import { ChartComponent } from './components/chart/chart.component';
import { TrackingInfoFormComponent } from './components/tracking-info-form/tracking-info-form.component';
import { LineEditorComponent } from './components/line-editor/line-editor.component';
import { BukTicketFormComponent } from './components/buk-ticket-form/buk-ticket-form.component';
import { BukTicketGroupSummaryViewComponent } from './components/buk-ticket-group-summary-view/buk-ticket-group-summary-view.component';
import { IENotiBarComponent } from './components/ie-noti-bar/ie-noti-bar.component';
import { LinkTicketPreviewComponent } from './components/link-ticket-preview/link-ticket-preview.component';
import { BukTicketPublicationMethodSelectorComponent } from './components/buk-ticket-publication-method-selector/buk-ticket-publication-method-selector.component';
import { BukTicketContentsViewComponent } from './components/buk-ticket-contents-view/buk-ticket-contents-view.component';
import { BukTicketPublishConfirmDialogComponent } from './components/buk-ticket-publish-confirm-dialog/buk-ticket-publish-confirm-dialog.component';
import { EmailTicketPreviewComponent } from './components/email-ticket-preview/email-ticket-preview.component';
import { BukTicketListViewComponent } from './components/buk-ticket-list-view/buk-ticket-list-view.component';
import { BukTicketIssuerComponent } from './components/buk-ticket-issuer/buk-ticket-issuer.component';
import { LinkTicketFormComponent } from './components/link-ticket-form/link-ticket-form.component';
import { EmailTicketFormComponent } from './components/email-ticket-form/email-ticket-form.component';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { FormFieldViewComponent } from './components/form-field-view/form-field-view.component';
import { PageTitleViewComponent } from './components/page-title-view/page-title-view.component';
import { CodeItemOptionSelectComponent } from './components/code-item-option-select/code-item-option-select.component';
import { HrComponent } from './components/hr/hr.component';
import { FilterComponent } from './components/filter/filter.component';

import { PeriodTranslatePipe } from './pipes/period-translate.pipe';
import { PlaintextPipe } from './pipes/plaintext.pipe';
import { PlainLengthPipe } from './pipes/plainlength.pipe';
import { ToDatePipe } from './pipes/to-date.pipe';
import { PricePipe } from './pipes/price.pipe';
import { CurrencyNumberPipe } from './pipes/currency-number.pipe';
import { NaverTrackingUrlPipe } from './pipes/naver-tracking-url.pipe';
import { ResizePipe } from './pipes/resize.pipe';
import { RouterModule } from '@angular/router';

import { SearchFilterPipe } from './pipes/search-filter.pipe';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MaterialCommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    ChartsModule,
    RouterModule,
    Angulartics2Module,
  ],
  declarations: [
    UserDebugInfoComponent,
    ImageInputComponent,
    ConfirmDialogComponent,
    BukOembedCardComponent,
    CommonHeaderComponent,
    MomentPeriodDatepickerComponent,
    PaginatorComponent,
    ChartComponent,
    TrackingInfoFormComponent,
    LineEditorComponent,
    PeriodTranslatePipe,
    PlaintextPipe,
    PlainLengthPipe,
    ToDatePipe,
    PricePipe,
    CurrencyNumberPipe,
    NaverTrackingUrlPipe,
    ResizePipe,
    BukTicketFormComponent,
    BukTicketGroupSummaryViewComponent,
    IENotiBarComponent,
    LinkTicketPreviewComponent,
    BukTicketPublicationMethodSelectorComponent,
    BukTicketContentsViewComponent,
    BukTicketPublishConfirmDialogComponent,
    EmailTicketPreviewComponent,
    BukTicketListViewComponent,
    BukTicketIssuerComponent,
    LinkTicketFormComponent,
    EmailTicketFormComponent,
    SectionTitleComponent,
    FormFieldViewComponent,
    PageTitleViewComponent,
    SearchFilterPipe,
    CodeItemOptionSelectComponent,
    HrComponent,
    FilterComponent,
  ],
  exports: [
    UserDebugInfoComponent,
    ImageInputComponent,
    ConfirmDialogComponent,
    BukOembedCardComponent,
    CommonHeaderComponent,
    MomentPeriodDatepickerComponent,
    PaginatorComponent,
    ChartComponent,
    TrackingInfoFormComponent,
    LineEditorComponent,
    PeriodTranslatePipe,
    PlaintextPipe,
    PlainLengthPipe,
    ToDatePipe,
    PricePipe,
    CurrencyNumberPipe,
    NaverTrackingUrlPipe,
    ResizePipe,
    BukTicketFormComponent,
    BukTicketGroupSummaryViewComponent,
    IENotiBarComponent,
    LinkTicketPreviewComponent,
    BukTicketPublicationMethodSelectorComponent,
    BukTicketContentsViewComponent,
    BukTicketPublishConfirmDialogComponent,
    EmailTicketPreviewComponent,
    BukTicketListViewComponent,
    BukTicketIssuerComponent,
    LinkTicketFormComponent,
    EmailTicketFormComponent,
    SectionTitleComponent,
    FormFieldViewComponent,
    PageTitleViewComponent,
    SearchFilterPipe,
    CodeItemOptionSelectComponent,
    HrComponent,
    FilterComponent,
  ],
  providers: [CurrencyNumberPipe],
})
export class SharedModule {}
