import i18nCacheBusting from './i18n-cache-busting';

export const environment = {
  production: true,
  appVersion: '2.0.3',
  editorVersion: '0.4.1',
  i18nCacheBusting,
  apiEndpoint: 'https://buk.io/api/v1',
  regacyAPIEndpoint: `https://buk.io/api`,
  graphApiEndpoint: 'https://graph.buk.io',
  bukOrigin: 'https://buk.io',
  journalUrl: 'https://journal.kr',
  editorClipDeck: '240.0.0.22',
  editorClipDeckEn: '240.0.0.26',
  editorBookmarkedClipListItems: {
    manage: '190.100.2.186',
    placeholder: '190.100.2.187',
    manageEn: '190.100.3.80',
    placeholderEn: '190.100.3.81',
  },
  channelIdForRechargingCredits: 'ca3997',
  goodsIdForRechargingCredits: {
    1001: '1000062',
    1010: '1000063',
    1020: '1000064',
    1030: '1000065',
    1040: '1000066',
    1050: '1000067',
    1100: '1000068',
  },
};
