import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Cookie } from 'ng2-cookies';

import { UserService } from '../../user.service';
import { map, switchMap } from 'rxjs/operators';

function getBrowserLanguage(): string {
  return (
    Cookie.get('language') ||
    (navigator.language.split('-')[0] === 'ko' ? 'ko' : 'en')
  );
}

@Injectable({
  providedIn: 'root',
})
export class AppTranslateService {
  constructor(translateService: TranslateService, userService: UserService) {
    translateService.onLangChange
      .pipe(switchMap(() => translateService.get('common.titles.appTitle')))
      .subscribe((translated) => {
        document.title = translated;
      });

    const userLang$ = userService.user$.pipe(
      map((user) => user?.language || getBrowserLanguage()),
    );

    userLang$.subscribe((userLang) => {
      translateService.use('ko');
    });
  }
}
